import { ChatClientTagType } from 'graphql/apollo-graphql-generated/globalTypes';
import {
  LoginMutation_login,
  LoginMutation_login_user,
  LoginMutation_login_user_agent_agencies,
  LoginMutation_login_user_agent_agencies_chatWidgets,
  LoginMutation_login_user_agent_agencies_chatWidgets_externalChatChannels,
  LoginMutation_login_user_agent_agencies_parent_realEstateAgenciesDatabases,
  LoginMutation_login_user_departments,
  LoginMutation_login_user_externalChatChannelsPermissions,
  LoginMutation_login_user_rouletteEvents,
  LoginMutation_login_user_salesGroupsReport,
  LoginMutation_login_user_telegramNotificationSettings,
  LoginMutation_login_user_whatsappNotificationSettings
} from 'graphql/User/apollo-graphql-generated/LoginMutation';
import castArray from 'lodash/castArray';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createContext } from 'react';
import { UserSettings } from './UserSettings';

export type AuthPayload = LoginMutation_login
export type UserInterface = LoginMutation_login_user


export interface UserContextInterface {
  user: User
  selectedWidgetIds: string[],
  selectedAgencyIds: string[],
  selectedAgencyIdsMongo: string[],
  /** refetch the agencies list from the server */
  refetchAgencies?: () => void,
  /** indicates if the subscriptions socket is connected to the server */
  isSocketConnected?: boolean,
  settings?: UserSettings,
  onLogout?: (redirectTo?: string) => void
}

export const UserContext = createContext<UserContextInterface>({
  user: null,
  selectedWidgetIds: [],
  selectedAgencyIds: [],
  selectedAgencyIdsMongo: [],
})

interface WidgetIdTree {
  // starts with parent
  id: string
  name: string
  url: string
  child: {
    id: string
    name: string
    chatWidget: {
      id: string
      name: string
    }
  }
}

export default class User implements UserInterface {
  __typename: "User";
  id: string;
  idsMongo: string;
  email: string;
  name: string;
  nickname: string;
  role: UserInterface['role'];
  clientTagsPermissions: ChatClientTagType[];
  agent: UserInterface['agent'];
  phone: string;
  telegramUser: string;
  telegramChatId: string;
  telegramNotificationSettings: LoginMutation_login_user_telegramNotificationSettings | null;
  whatsappNotificationSettings: LoginMutation_login_user_whatsappNotificationSettings | null;
  whatsappPhoneNumber: string;
  clientsCount: number;
  loginsReport: UserInterface['loginsReport'];
  salesGroupsReport: LoginMutation_login_user_salesGroupsReport;
  disabled: boolean;
  externalChatChannelsPermissions: LoginMutation_login_user_externalChatChannelsPermissions[] | null;
  rouletteEvents: LoginMutation_login_user_rouletteEvents[] | null;
  departments: LoginMutation_login_user_departments[];
  si9Code: number;

  constructor(userInterface: UserInterface) {
    Object.assign(this, userInterface)
  }

  public getExternalChatChannelsPermissionsById(id: string): LoginMutation_login_user_externalChatChannelsPermissions {
    if (!this.externalChatChannelsPermissions) return null;
    return this.externalChatChannelsPermissions.find(a => a.id === id);
  }

  public getAgencyChildById(id: string) {
    return this.agent.agencies.find(a => a.id === id)
  }

  public getAgencyIdsMongo(id: string): string[] {
    for (let i = 0; i < this.agent.agencies.length; i++) {
      if (this.agent.agencies[i].id === id) {
        return this.agent.agencies[i].parent.idsMongo
      }
    }
    return []
  }

  public getAgencyParents(agencyChildIds?: string[]) {
    if (!this.agent || !this.agent.agencies) return null
    const agencies = agencyChildIds ?
      this.agent.agencies.filter(a => agencyChildIds.includes(a.id)) :
      this.agent.agencies
    return uniqBy(agencies.map(a => a.parent), (parent => parent.id))
  }

  public getAgencyParentIds(agencyChildIds?: string[]): string[] {
    const parents = this.getAgencyParents(agencyChildIds)
    if (!parents) return null
    return parents.map(p => p.id)
  }

  public getAllAgencyIds(): string[] {
    if (!this.agent || !this.agent.agencies) return null
    return this.agent.agencies.map(agency => agency.id)
  }

  public getAllExternalChatChannelPermissionsIds(): string[] {
    if (!this.externalChatChannelsPermissions) return null
    return this.externalChatChannelsPermissions.map(e => e.id)
  }

  public getAllExternalChatChannelsIds(): string[] {
    if (!this.agent || !this.agent.agencies) return null;
    const chatWidgets = flatten(this.agent.agencies.map(ag => ag.chatWidgets));
    const externalChatChannels = flatten(chatWidgets.map(cw => cw.externalChatChannels));
    return flatten(externalChatChannels.map(ex => ex.id));
  }

  public getAllExternalChatChannelsIdsWithPermission(): string[] {
    if (!this.agent || !this.agent.agencies) return null;
    const chatWidgets = flatten(this.agent.agencies.map(ag => ag.chatWidgets));
    const externalChatChannels = flatten(chatWidgets.map(cw => cw.externalChatChannels));

    const externalChatChannelsWithPermission = externalChatChannels
      .filter(cur => !cur.disabled)
      .filter(cur => this?.externalChatChannelsPermissions?.some(permission => permission?.externalChatChannel?.id === cur.id));

    return flatten(externalChatChannelsWithPermission.map(ex => ex.id));
  }

  public getAllAuthorizedExternalChatChannelsIds(): string[] {
    return this.externalChatChannelsPermissions.map(cur => cur.externalChatChannel.id)
  }

  public getSelectedExternalChatChannels(selectedWidgetIds: string[]): LoginMutation_login_user_agent_agencies_chatWidgets_externalChatChannels[] {
    if (!this.agent || !this.agent.agencies) return null;
    const selectedChatWidgets = flatten(this.agent.agencies.map(ag => ag.chatWidgets)).filter(cur => selectedWidgetIds.includes(cur.id));
    const externalChatChannels = flatten(selectedChatWidgets.map(cw => cw.externalChatChannels));
    return externalChatChannels
  }

  public getSelectedExternalChatChannelsIds(selectedWidgetIds: string | string[]): string[] {
    if (!this.agent || !this.agent.agencies) return null;
    const selectedWidgetIdsArray = castArray(selectedWidgetIds)
    const externalChatChannels = this.getSelectedExternalChatChannels(selectedWidgetIdsArray)
    return externalChatChannels.map(ex => ex.id);
  }

  public getAllDepartmentsIds(): string[] {
    if (!this.agent || !this.agent.agencies) return null;
    const departments = flatten(this.agent.agencies.map(ag => ag.departments));
    return flatten(departments.map(cur => cur?.id));
  }

  public getAllAuthorizedDepartmentsIds(): string[] {
    return this.departments.map(cur => cur.id)
  }

  public getAllDepartments() {
    if (!this.agent || !this.agent.agencies) return null;
    const departments = flatten(this.agent.agencies.map(ag => ag.departments));
    return flatten(departments);
  }

  public getAllWidgetIds(): string[] {
    if (!this.agent || !this.agent.agencies) return null
    return uniq(
      flatten(
        this.agent.agencies.map(agency => agency.chatWidgets.map(c => c.id))
      )
    )
  }

  public getAllChatWidgets(selectedWidgetIds?: string[]): (LoginMutation_login_user_agent_agencies_chatWidgets & { namePretty: string })[] {
    if (!this.agent || !this.agent.agencies) return null
    return uniq(
      flatten(
        this.agent.agencies.map(agency => {
          const agencyName = agency.name

          return agency.chatWidgets
            .map(widget => {
              const widgetName = widget.name || 'Sem Nome'
              return {
                ...widget,
                namePretty: agencyName + ' (' + widgetName + ')',
              }
            })
            .filter(widget => selectedWidgetIds ? selectedWidgetIds.includes(widget.id) : true)
        })
      )
    )
  }

  /** if the user has only one agency, returns the default widgetId for that agency. otherwise, returns null. */
  public getDefaultWidgetIdIfAgencyIsUnique(): string | null {
    if (!this.agent || !this.agent.agencies) return null
    if (isEmpty(this.agent.agencies) || this.agent.agencies.length > 1) return null
    if (!this.agent.agencies[0].chatWidgets[0]) return null
    return this.agent.agencies[0].chatWidgets[0].id
  }

  /** returns all the parent, child and widget ids the user has access to */
  public getAllAgencyAndWidgetIds(): string[] {
    if (!this.agent || !this.agent.agencies) return null
    return uniq(flatten([
      this.agent.agencies.map(agency => agency.id),
      this.agent.agencies.map(agency => agency.parent.id),
      flatten(this.agent.agencies.map(agency => agency.chatWidgets.map(c => c.id)))
    ]))
  }

  public getAgencyChildByExternalChatChannelId(externalChatChannelId: string): LoginMutation_login_user_agent_agencies {
    if (!this.agent || !this.agent.agencies) return null
    return this.agent.agencies.find(agency => agency.chatWidgets
      .some(widget => widget.externalChatChannels
        .some(cur => cur.id === externalChatChannelId)))
  }

  public getAgencyChildByDepartmentId(departmentId: string): LoginMutation_login_user_agent_agencies {
    if (!this.agent || !this.agent.agencies) return null
    return this.agent.agencies.find(agency => agency.departments.some(department => department.id === departmentId))
  }

  public getAgencyChildByWidgetId(widgetId: string): LoginMutation_login_user_agent_agencies {
    if (!this.agent || !this.agent.agencies) return null
    return this.agent.agencies.find(agency => agency.chatWidgets.some(widget => widget.id === widgetId))
  }

  public getAgencyParentIdByWidgetId(widgetId: string): string {
    if (!this.agent || !this.agent.agencies) return null
    return this?.agent?.agencies?.find(agency => agency?.chatWidgets?.some(widget => widget?.id === widgetId))?.parent?.id
  }

  public getWidgetIdTree(widgetId: string): WidgetIdTree {
    if (!this.agent || !this.agent.agencies) return null
    for (const child of this.agent.agencies) {
      for (const chatWidget of child.chatWidgets) {
        if (chatWidget.id === widgetId) {
          return {
            id: child.parent.id,
            name: child.parent.name,
            url: child.parent.url,
            child: {
              id: child.id,
              name: child.name,
              chatWidget: {
                id: chatWidget.id,
                name: chatWidget.name,
              }
            },
          }
        }
      }
    }
    return null
  }

  /** returns a string in the format "Apolar / Água Verde / site" */
  public getPathStringForWidgetId(widgetId: string): string | null {
    const tree = this.getWidgetIdTree(widgetId)
    if (!tree) return null

    return `${tree.name} / ${tree.child.name} / ${tree.child.chatWidget.name}`
  }

  public getAgenciesDatabases(agencyChildIds?: string[]): LoginMutation_login_user_agent_agencies_parent_realEstateAgenciesDatabases[] {
    return uniqBy(flatten(
      this.getAgencyParents(agencyChildIds).map(a => a.realEstateAgenciesDatabases).filter(el => !!el)
    ), '_id')
  }

  public getAgencyDatabaseIdsFromExternalChatChannelId(externalChatChannelId: string) {
    const agency = this.agent.agencies.find(cur => cur.chatWidgets.some(chatWidget => chatWidget.externalChatChannels.some(externalChatChannel => externalChatChannel.id === externalChatChannelId)));
    return agency?.parent?.idsMongo || [];
  }

  public getAgencyDatabaseFromId(id: string): LoginMutation_login_user_agent_agencies_parent_realEstateAgenciesDatabases {
    const agenciesDatabases = flatten(this.getAgencyParents().map(parent => parent.realEstateAgenciesDatabases))
    return agenciesDatabases.filter(el => !!el).find(a => a._id === id)
  }

  public getWidgetIdsByAgencyId(agencyId: string) {
    const agencies = this.agent.agencies;
    const agency = agencies.find(a => agencyId === a.id)
    if (!agency) return null
    return agency.chatWidgets.map(c => c.id)
  }

  public getWidgetIdsAndNamesByAgencyParentId(agencyParentId: string) {
    const agencies = this.agent.agencies?.filter(a => a.parent.id === agencyParentId);
    if (!agencies?.length) return null
    const chatWidgets = agencies?.map(agency => agency?.chatWidgets?.map(chatWidget => ({ id: chatWidget?.id, name: chatWidget?.name, agency: agency?.name })));
    return flatten(chatWidgets);
  }

  public getWidgetIdsAndNamesByAgencyId(agencyId: string) {
    const agencies = this.agent.agencies;
    const agency = agencies.find(a => agencyId === a.id)
    if (!agency) return null
    return agency.chatWidgets.map(c => ({
      id: c.id,
      name: c.name
    }))
  }

  /**
   * @param selectedWidgetIds (optional, default uses all) selected agency ids which should be considered to calculated
   * @returns object containing booleans about dealType allowance
   */
  public getAllowedDealTypes(selectedWidgetIds?: string[]): { allowRent: boolean, allowBuy: boolean } {

    const agencies = this.agent.agencies;
    const allowedDealTypes: string[] = [];

    for (let a = 0; a < agencies.length; a++) {
      const ag = agencies[a];
      if (!selectedWidgetIds || selectedWidgetIds.some(selectedWidgetId => ag.chatWidgets.some(acw => acw.id === selectedWidgetId))) {
        //console.log('consideres ', ag.name, 'disabled?', ag.disabledDealType)
        if (!ag.disabledDealType) {
          return { allowRent: true, allowBuy: true };
        } else if (ag.disabledDealType === 'BUY' && !allowedDealTypes.includes('RENT')) {
          allowedDealTypes.push('RENT');
        } else if (ag.disabledDealType === 'RENT' && !allowedDealTypes.includes('BUY')) {
          allowedDealTypes.push('BUY');
        }
      }
    }
    //console.log(allowedDealTypes.includes('BUY'), allowedDealTypes.includes('RENT'))
    return { allowBuy: allowedDealTypes.includes('BUY'), allowRent: allowedDealTypes.includes('RENT') };
  }

  /** 
   * Return array of all the external chat channels acessible to the user 
   * @param widgetIdsFilter filter external chat channels by widget id
   * 
   */
  public getExternalChatChannels(widgetIdsFilter?: string | string[]): LoginMutation_login_user_agent_agencies_chatWidgets_externalChatChannels[] {
    const widgetIdsFilterArray = widgetIdsFilter && castArray(widgetIdsFilter)
    const res: LoginMutation_login_user_agent_agencies_chatWidgets_externalChatChannels[] = []
    for (const agency of this.agent.agencies || []) {
      for (const widget of agency.chatWidgets || []) {
        if (widgetIdsFilterArray && !widgetIdsFilterArray.includes(widget.id))
          continue

        for (const externalChatChannel of widget.externalChatChannels || []) {
          if (externalChatChannel) {
            res.push(externalChatChannel)
          }
        }
      }
    }
    return res
  }

  /** find the first whatsapp channel that is registered */
  public getFirstWhatsappExternalChannel(): LoginMutation_login_user_agent_agencies_chatWidgets_externalChatChannels {
    const externalChatChannels = this.getExternalChatChannels()
    return externalChatChannels.find(cur => cur.type === 'WHATSAPP')
  }

  public getWidgetIdFromExternalChatChannelId(externalChatChannelId: string): string {
    for (const agency of this.agent.agencies) {
      for (const widget of agency.chatWidgets) {
        for (const externalChatChannel of widget.externalChatChannels) {
          if (externalChatChannel.id === externalChatChannelId) {
            return widget.id
          }
        }
      }
    }
    return null
  }

  public getWidgetIdsFromExternalChatChannelIds(externalChatChannelIds: string[]): string[] {
    const widgets = this.getAllChatWidgets();
    return widgets?.filter(chatWidget => chatWidget?.externalChatChannels?.some(cur => externalChatChannelIds?.includes(cur?.id)))?.map(cur => cur?.id)
  }

  public getAgencyChildIdFromExternalChatChannelId(externalChatChannelId: string): string {
    for (const agency of this.agent.agencies) {
      for (const widget of agency.chatWidgets) {
        for (const externalChatChannel of widget.externalChatChannels) {
          if (externalChatChannel.id === externalChatChannelId) {
            return agency.id
          }
        }
      }
    }
    return null
  }

  public getExternalChannelById(externalChatChannelId: string): LoginMutation_login_user_agent_agencies_chatWidgets_externalChatChannels {
    const externalChatChannels = this.getExternalChatChannels()
    return externalChatChannels.find(cur => cur.id === externalChatChannelId)
  }

  public getExternalChannelNameById(externalChatChannelId: string): string {
    const externalChatChannel = this.getExternalChannelById(externalChatChannelId)
    const agency = this.getAgencyChildByExternalChatChannelId(externalChatChannelId)
    const channelName = externalChatChannel?.name || 'Sem Nome';
    const agencyName = agency?.name
    return agencyName + ' (' + channelName + ')';
  }

  public agencyHasDefaultFunnelStep(agencyId: string) {
    const agencies = this.agent.agencies;
    const agency = agencies.find(a => agencyId === a.id)
    if (!agency) return null
    return !!agency.defaultFunnelStep
  }

  public getFunnelStepNameById(funnelStepId: string, agencyId: string) {
    const agencies = this.agent.agencies;
    const agency = agencies.find(a => agencyId === a.id)
    if (!agency || !agency?.funnelSteps?.length) return null
    return agency?.funnelSteps?.find(cur => cur?.id === funnelStepId)?.name

  }

  public getCustomAuxTagTextsByAgencyId(agencyId: string) {
    return this.getAgencyChildById(agencyId)?.customAuxTagTexts;
  }

  public getCustomAuxTagTextsByWidgetId(widgetId: string) {
    return this.getAgencyChildByWidgetId(widgetId)?.customAuxTagTexts;
  }

  public getCustomAuxTagTextsByExternalChatChannelId(externalChatChannelIdId: string) {
    return this.getAgencyChildByExternalChatChannelId(externalChatChannelIdId)?.customAuxTagTexts;
  }

  public isApolarChatWidget(widgetId: string): boolean {
    const agencies = this.agent.agencies;
    const parent = agencies?.find(cur => cur?.chatWidgets?.some(cur => cur?.id === widgetId))?.parent;
    return parent?.name === 'Apolar';
  }

  public isApolarAgent(): boolean {
    return this?.agent?.agencies?.some(cur => cur?.name?.toLowerCase()?.includes('apolar') || cur?.parent?.name?.toLowerCase()?.includes('apolar'))
  }

  public getAgencyChildrenByExternalChatChannelIds(externalChatChannelIds: string[]) {
    const agencies = this.agent?.agencies
      ?.filter(agency => agency?.chatWidgets
        ?.some(chatWidget => externalChatChannelIds?.includes(chatWidget?.id) || chatWidget?.externalChatChannels
          ?.some(externalChatChannel => externalChatChannelIds?.includes(externalChatChannel?.id))))
    return uniqBy(agencies, (cur) => cur?.id).filter((cur) => !!cur)
  }

  public getAllFunnelSteps() {
    const agencies = this.agent.agencies;
    return flatten(agencies?.map(cur => cur.funnelSteps));

  }
}
