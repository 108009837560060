import gql from 'graphql-tag'
import { MutationHookOptions, useMutation } from '@apollo/client';
import { RenewLoginTokenMutation, RenewLoginTokenMutationVariables } from './apollo-graphql-generated/RenewLoginTokenMutation';
import { LoginMutation, LoginMutationVariables } from './apollo-graphql-generated/LoginMutation';
import { LoginHarryMutation, LoginHarryMutationVariables } from './apollo-graphql-generated/LoginHarryMutation';
import { ChangePasswordMutation, ChangePasswordMutationVariables } from './apollo-graphql-generated/ChangePasswordMutation';
import { ResetPasswordMutationVariables, ResetPasswordMutation } from './apollo-graphql-generated/ResetPasswordMutation';
import { AskForPasswordResetMutation, AskForPasswordResetMutationVariables } from './apollo-graphql-generated/AskForPasswordResetMutation';
import { UpdateUserMutation, UpdateUserMutationVariables } from './apollo-graphql-generated/UpdateUserMutation';
import { AddAgenciesToUserMutation, AddAgenciesToUserMutationVariables } from './apollo-graphql-generated/AddAgenciesToUserMutation';
import { RemoveAgenciesFromUserMutation, RemoveAgenciesFromUserMutationVariables } from './apollo-graphql-generated/RemoveAgenciesFromUserMutation';
import { AddExternalChatChannelPermissionsToUserMutation, AddExternalChatChannelPermissionsToUserMutationVariables } from './apollo-graphql-generated/AddExternalChatChannelPermissionsToUserMutation';
import { RemoveExternalChatChannelPermissionsFromUserMutation, RemoveExternalChatChannelPermissionsFromUserMutationVariables } from './apollo-graphql-generated/RemoveExternalChatChannelPermissionsFromUserMutation';
import { RouletteCheckinMutation, RouletteCheckinMutationVariables } from "./apollo-graphql-generated/RouletteCheckinMutation";
import { RouletteCheckoutMutation, RouletteCheckoutMutationVariables } from "./apollo-graphql-generated/RouletteCheckoutMutation";
import { SignupMutation, SignupMutationVariables } from './apollo-graphql-generated/SignupMutation';
import { SetUserEnabledMutation, SetUserEnabledMutationVariables } from './apollo-graphql-generated/SetUserEnabledMutation';
import { AddDepartmentsToUserMutation, AddDepartmentsToUserMutationVariables } from './apollo-graphql-generated/AddDepartmentsToUserMutation';
import { RemoveDepartmentsFromUserMutation, RemoveDepartmentsFromUserMutationVariables } from './apollo-graphql-generated/RemoveDepartmentsFromUserMutation';
import { MoveClientsToAnotherUserMutation, MoveClientsToAnotherUserMutationVariables } from './apollo-graphql-generated/MoveClientsToAnotherUserMutation';
import { USER_FRAGMENT, USER_AGENCY_FRAGMENT, USER_WITH_ALL_AGENCIES_FRAGMENT } from './UserFragments';
import { AddPagesToUserMutation, AddPagesToUserMutationVariables } from './apollo-graphql-generated/AddPagesToUserMutation';
import { RemovePagesFromUserMutation, RemovePagesFromUserMutationVariables } from './apollo-graphql-generated/RemovePagesFromUserMutation';
import { DeleteUserMutation, DeleteUserMutationVariables } from './apollo-graphql-generated/DeleteUserMutation';
import { UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables } from './apollo-graphql-generated/UpdateNotificationSettingsMutation';
import { LoginExternalMutation, LoginExternalMutationVariables } from './apollo-graphql-generated/LoginExternalMutation';

export const UPDATE_USER_MUTATION = gql`
mutation UpdateUserMutation (
  $id: String!,
  $email: String!,
  $name: String!,
  $nickname: String
  $role: UserRole,
  $clientTagsPermissions: [ChatClientTagType!],
  $telegramUser: String,
  $telegramChatId: String,
  $phone: String,
  $whatsappPhoneNumber: String,
  $si9Code: Int,
) {
  updateUser (
    id: $id,
    email: $email,
    name: $name,
    nickname: $nickname,
    role: $role,
    clientTagsPermissions: $clientTagsPermissions,
    telegramUser: $telegramUser,
    telegramChatId: $telegramChatId,
    phone: $phone,
    whatsappPhoneNumber: $whatsappPhoneNumber,
    si9Code: $si9Code,
  ) {
      ...UserFragment
  }
}
${USER_FRAGMENT}
`

export const ADD_AGENCIES_TO_USER_MUTATION = gql`
mutation AddAgenciesToUserMutation ($userId: String!, $agencyIds: [String!]!) {
  addAgenciesToUser (userId: $userId, agencyIds: $agencyIds) {
    id
    agent {
      agencies {
        ...UserAgencyFragment
      }
    }
  }
}
${USER_AGENCY_FRAGMENT}
`

export const REMOVE_AGENCIES_FROM_USER_MUTATION = gql`
mutation RemoveAgenciesFromUserMutation ($userId: String!, $agencyIds: [String!]!) {
  removeAgenciesFromUser (userId: $userId, agencyIds: $agencyIds) {
    id
    agent {
      agencies {
        ...UserAgencyFragment
      }
    }
  }
}
${USER_AGENCY_FRAGMENT}
`

export const ADD_PAGES_TO_USER_MUTATION = gql`
mutation AddPagesToUserMutation ($userId: String!, $pages: [DashboardPage!]!) {
  addPagesToUser (userId: $userId, pages: $pages) {
    id
    agent {
      pages
    }
  }
}
`

export const REMOVE_PAGES_FROM_USER_MUTATION = gql`
mutation RemovePagesFromUserMutation ($userId: String!, $pages: [DashboardPage!]!) {
  removePagesFromUser (userId: $userId, pages: $pages) {
    id
    agent {
      pages
    }
  }
}
`

export const ADD_EXTERNAL_CHAT_CHANNELS_TO_USER_MUTATION = gql`
mutation AddExternalChatChannelPermissionsToUserMutation ($userId: String!, $externalChatChannelIds: [String!]!) {
  addExternalChatChannelPermissionsToUser (userId: $userId, externalChatChannelIds: $externalChatChannelIds)
}
`

export const REMOVE_EXTERNAL_CHAT_CHANNELS_FROM_USER_MUTATION = gql`
mutation RemoveExternalChatChannelPermissionsFromUserMutation ($userId: String!, $externalChatChannelIds: [String!]!) {
  removeExternalChatChannelPermissionsFromUser (userId: $userId, externalChatChannelIds: $externalChatChannelIds)
}
`

export const SET_USER_ENABLED_MUTATION = gql`
mutation SetUserEnabledMutation (
  $userId: String!,
  $enabled: Boolean!,
  $userEmailToShareClients: String
) {
  setUserEnabled (
    userId: $userId,
    enabled: $enabled,
    userEmailToShareClients: $userEmailToShareClients
  ) {
    id
    disabled
  }
}
`

export const SIGNUP_MUTATION = gql`
mutation SignupMutation (
  $email: String!,
  $password: String,
  $name: String!,
  $nickname: String,
  $role: UserRole!,
  $clientTagsPermissions: [ChatClientTagType!],
  $agencyIds: [String!],
  $sendNewUserEmail: Boolean,
  $telegramUser: String,
  $telegramChatId: String,
  $phone: String,
  $whatsappPhoneNumber: String,
  $si9Code: Int,
) {
  signup (
    email: $email,
    password: $password
    name: $name,
    nickname: $nickname,
    role: $role,
    clientTagsPermissions: $clientTagsPermissions,
    agencyIds: $agencyIds,
    sendNewUserEmail: $sendNewUserEmail,
    telegramUser: $telegramUser,
    telegramChatId: $telegramChatId,
    phone: $phone,
    whatsappPhoneNumber: $whatsappPhoneNumber,
    si9Code: $si9Code,
  ) {
    ...UserFragment
  }
}
${USER_FRAGMENT}
`

export const LOGIN_MUTATION = gql`
mutation LoginMutation ($email: String!, $password: String!) {
  login (email: $email, password: $password) {
    token
    user {
      ...UserWithAllAgenciesFragment
    }
  }
}
${USER_WITH_ALL_AGENCIES_FRAGMENT}
`

export const LOGIN_HARRY_MUTATION = gql`
mutation LoginHarryMutation ($token: String!) {
  loginHarry (token: $token) {
    token
    user {
      ...UserWithAllAgenciesFragment
    }
  }
}
${USER_WITH_ALL_AGENCIES_FRAGMENT}
`

export const LOGIN_EXTERNAL_MUTATION = gql`
mutation LoginExternalMutation ($token: String!, $externalPartner: ExternalPartner!) {
  loginExternal (token: $token, externalPartner: $externalPartner) {
    token
    user {
      ...UserWithAllAgenciesFragment
    }
  }
}
${USER_WITH_ALL_AGENCIES_FRAGMENT}
`

export const RENEW_LOGIN_TOKEN_MUTATION = gql`
mutation RenewLoginTokenMutation ($token: String!) {
  renewLoginToken (token: $token) {
    token
    user {
      ...UserWithAllAgenciesFragment
    }
  }
}
${USER_WITH_ALL_AGENCIES_FRAGMENT}
`

export const CHANGE_PASSWORD_MUTATION = gql`
mutation ChangePasswordMutation 
($email: String!
 $password: String!
 $newPassword: String!) {
  changePassword 
  (email: $email,
   password: $password
   newPassword: $newPassword
  ) {
    token
    user {
      email
    }
  }
} `;

export const RESET_PASSWORD_MUTATION = gql`
mutation ResetPasswordMutation 
($code: String!
 $newPassword: String!) {
  resetPassword 
  (code: $code
   newPassword: $newPassword) {
    token
  }
} `;

export const ASK_FOR_PASSWORD_RESET_MUTATION = gql`
mutation AskForPasswordResetMutation 
($email: String!) {
  askForPasswordReset 
  (email: $email) 
} `;

export const ROULETTE_CHECKIN_MUTATION = gql`
mutation RouletteCheckinMutation ($id: String!) {
  rouletteCheckin(id: $id)
}
`

export const ROULETTE_CHECKOUT_MUTATION = gql`
mutation RouletteCheckoutMutation ($id: String!) {
  rouletteCheckout(id: $id)
}
`

export const ADD_DEPARTMENTS_TO_USER_MUTATION = gql`
mutation AddDepartmentsToUserMutation ($userId: String!, $departmentIds: [String!]!) {
  addDepartmentsToUser (userId: $userId, departmentIds: $departmentIds)
}
`

export const REMOVE_DEPARTMENTS_FROM_USER_MUTATION = gql`
mutation RemoveDepartmentsFromUserMutation ($userId: String!, $departmentIds: [String!]!) {
  removeDepartmentsFromUser (userId: $userId, departmentIds: $departmentIds)
}
`

export const MOVE_CLIENTS_TO_ANOTHER_USER_MUTATION = gql`
mutation MoveClientsToAnotherUserMutation ($fromUserId: String!, $toUserId: String!, $moveInsteadOfCopy: Boolean) {
  moveClientsToAnotherUser (fromUserId: $fromUserId, toUserId: $toUserId, moveInsteadOfCopy: $moveInsteadOfCopy)
}
`

export const DELETE_USER_MUTATION = gql`
mutation DeleteUserMutation ($userId: String!, $userEmailToShareClients: String) {
  deleteUser(userId: $userId, userEmailToShareClients: $userEmailToShareClients)
}
`

export const UPDATE_NOTIFICATION_SETTINGS_MUTATION = gql`
mutation UpdateNotificationSettingsMutation($id: String!, $notificationSettings: NotificationSettingsInput!) {
  updateNotificationSettings(id: $id, notificationSettings: $notificationSettings)
}
`

//
// Custom Hooks
//

export function useUpdateUserMutation(options?: MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
  return useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UPDATE_USER_MUTATION, options)
}

export function useAddAgenciesToUserMutation(options?: MutationHookOptions<AddAgenciesToUserMutation, AddAgenciesToUserMutationVariables>) {
  return useMutation<AddAgenciesToUserMutation, AddAgenciesToUserMutationVariables>(ADD_AGENCIES_TO_USER_MUTATION, options)
}

export function useRemoveAgenciesFromUserMutation(options?: MutationHookOptions<RemoveAgenciesFromUserMutation, RemoveAgenciesFromUserMutationVariables>) {
  return useMutation<RemoveAgenciesFromUserMutation, RemoveAgenciesFromUserMutationVariables>(REMOVE_AGENCIES_FROM_USER_MUTATION, options)
}

export function useAddPagesToUserMutation(options?: MutationHookOptions<AddPagesToUserMutation, AddPagesToUserMutationVariables>) {
  return useMutation<AddPagesToUserMutation, AddPagesToUserMutationVariables>(ADD_PAGES_TO_USER_MUTATION, options)
}

export function useRemovePagesFromUserMutation(options?: MutationHookOptions<RemovePagesFromUserMutation, RemovePagesFromUserMutationVariables>) {
  return useMutation<RemovePagesFromUserMutation, RemovePagesFromUserMutationVariables>(REMOVE_PAGES_FROM_USER_MUTATION, options)
}

export function useAddExternalChatChannelPermissionsToUserMutation(options?: MutationHookOptions<AddExternalChatChannelPermissionsToUserMutation, AddExternalChatChannelPermissionsToUserMutationVariables>) {
  return useMutation<AddExternalChatChannelPermissionsToUserMutation, AddExternalChatChannelPermissionsToUserMutationVariables>(ADD_EXTERNAL_CHAT_CHANNELS_TO_USER_MUTATION, options)
}

export function useRemoveExternalChatChannelPermissionsFromUserMutation(options?: MutationHookOptions<RemoveExternalChatChannelPermissionsFromUserMutation, RemoveExternalChatChannelPermissionsFromUserMutationVariables>) {
  return useMutation<RemoveExternalChatChannelPermissionsFromUserMutation, RemoveExternalChatChannelPermissionsFromUserMutationVariables>(REMOVE_EXTERNAL_CHAT_CHANNELS_FROM_USER_MUTATION, options)
}

export function useSetUserEnabledMutation(options?: MutationHookOptions<SetUserEnabledMutation, SetUserEnabledMutationVariables>) {
  return useMutation<SetUserEnabledMutation, SetUserEnabledMutationVariables>(SET_USER_ENABLED_MUTATION, options)
}

export function useSignupMutation(options?: MutationHookOptions<SignupMutation, SignupMutationVariables>) {
  return useMutation<SignupMutation, SignupMutationVariables>(SIGNUP_MUTATION, options)
}

export function useLoginMutation(options?: MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  return useMutation<LoginMutation, LoginMutationVariables>(LOGIN_MUTATION, options)
}

export function useLoginHarryMutation(options?: MutationHookOptions<LoginHarryMutation, LoginHarryMutationVariables>) {
  return useMutation<LoginHarryMutation, LoginHarryMutationVariables>(LOGIN_HARRY_MUTATION, options)
}

export function useLoginExternalMutation(options?: MutationHookOptions<LoginExternalMutation, LoginExternalMutationVariables>) {
  return useMutation<LoginExternalMutation, LoginExternalMutationVariables>(LOGIN_EXTERNAL_MUTATION, options)
}

export function useRenewLoginTokenMutation(options?: MutationHookOptions<RenewLoginTokenMutation, RenewLoginTokenMutationVariables>) {
  return useMutation<RenewLoginTokenMutation, RenewLoginTokenMutationVariables>(RENEW_LOGIN_TOKEN_MUTATION, options)
}

export function useChangePasswordMutation(options?: MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
  return useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(CHANGE_PASSWORD_MUTATION, options)
}

export function useResetPasswordMutation(options?: MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
  return useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(RESET_PASSWORD_MUTATION, options)
}

export function useAskForPasswordResetMutation(options?: MutationHookOptions<AskForPasswordResetMutation, AskForPasswordResetMutationVariables>) {
  return useMutation<AskForPasswordResetMutation, AskForPasswordResetMutationVariables>(ASK_FOR_PASSWORD_RESET_MUTATION, options)
}

export function useRouletteCheckinMutation(options?: MutationHookOptions<RouletteCheckinMutation, RouletteCheckinMutationVariables>) {
  return useMutation<RouletteCheckinMutation, RouletteCheckinMutationVariables>(ROULETTE_CHECKIN_MUTATION, options)
}

export function useRouletteCheckoutMutation(options?: MutationHookOptions<RouletteCheckoutMutation, RouletteCheckoutMutationVariables>) {
  return useMutation<RouletteCheckoutMutation, RouletteCheckoutMutationVariables>(ROULETTE_CHECKOUT_MUTATION, options)
}

export function useAddDepartmentsToUserMutation(options?: MutationHookOptions<AddDepartmentsToUserMutation, AddDepartmentsToUserMutationVariables>) {
  return useMutation<AddDepartmentsToUserMutation, AddDepartmentsToUserMutationVariables>(ADD_DEPARTMENTS_TO_USER_MUTATION, options)
}

export function useRemoveDepartmentsFromUserMutation(options?: MutationHookOptions<RemoveDepartmentsFromUserMutation, RemoveDepartmentsFromUserMutationVariables>) {
  return useMutation<RemoveDepartmentsFromUserMutation, RemoveDepartmentsFromUserMutationVariables>(REMOVE_DEPARTMENTS_FROM_USER_MUTATION, options)
}

export function useMoveClientsToAnotherUserMutation(options?: MutationHookOptions<MoveClientsToAnotherUserMutation, MoveClientsToAnotherUserMutationVariables>) {
  return useMutation<MoveClientsToAnotherUserMutation, MoveClientsToAnotherUserMutationVariables>(MOVE_CLIENTS_TO_ANOTHER_USER_MUTATION, options)
}

export function useDeleteUserMutation(options?: MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
  return useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DELETE_USER_MUTATION, options)
}

export function useUpdateNotificationSettingsMutation(options?: MutationHookOptions<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>) {
  return useMutation<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>(UPDATE_NOTIFICATION_SETTINGS_MUTATION, options)
}
