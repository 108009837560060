import { ApolloClient, ApolloProvider, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { notification, message, Spin, Alert, ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from 'apollo-upload-client';
import React, { useEffect, useState, useMemo, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useRenewLoginTokenMutation } from '../../graphql/User/UserMutations';
import LoginForm from '../User/LoginForm';
import DashboardAuthenticatedRootPage from './DashboardAuthenticatedRootPage';
import User, { AuthPayload, UserInterface } from 'graphql/User/User';
import { USER_QUERY } from 'graphql/User/UserQueries';
import { UserQuery, UserQueryVariables } from 'graphql/User/apollo-graphql-generated/UserQuery';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import PagesController from './PagesController';
import ErrorBoundary from 'components/ErrorBoundary';
import NetworkErrorPage from 'components/NetworkErrorPage';
import { FrownOutlined } from '@ant-design/icons';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance'
import ptBR from 'antd/locale/pt_BR'

// import LogRocket from 'logrocket';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
// set global dayjs locale to portuguese
dayjs.locale('pt-br');

if (process.env.NODE_ENV === 'production') {
  // LogRocket.init('pyvtlx/firefly');
}

const TOKEN_RENEW_INTERVAL_MS = 24 * 60 * 60 * 1000; // 24 hours

/** Component that renews the user token every hour */
export const UserTokenRenewer = (props: { token: string; onSuccessfulTokenRenew: (authPayload: AuthPayload) => void }) => {
  const { token, onSuccessfulTokenRenew } = props;
  // renew token automatically
  const [renewTokenMutation] = useRenewLoginTokenMutation({
    variables: { token },
    update: (proxy, mutationResult) => {
      if (mutationResult.data.renewLoginToken) {
        // console.log(`Login Token renewed`);
        onSuccessfulTokenRenew(mutationResult.data.renewLoginToken);
      } else {
        // console.log("Login token not renewed")
      }
    },
  });

  //Renews token on component mount if more than 24 hours passed since last renew
  useEffect(() => {
    const lastRenewDateStr = localStorage.getItem(USER_TOKEN_RENEW_DATE_LOCAL_STORAGE_KEY);
    const lastRenewTime = lastRenewDateStr ? new Date(lastRenewDateStr).getTime() : 0;
    const timeSinceLastRenew = new Date().getTime() - lastRenewTime;

    if (token && timeSinceLastRenew > TOKEN_RENEW_INTERVAL_MS) renewTokenMutation();
    // eslint-disable-next-line
  }, []);

  //Renews token every 24 hours
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (token) renewTokenMutation();
    }, TOKEN_RENEW_INTERVAL_MS);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  return <></>;
};

const USER_LOCAL_STORAGE_KEY = 'firefly-dashboard-user';
const USER_TOKEN_LOCAL_STORAGE_KEY = 'firefly-dashboard-user-token';
const USER_TOKEN_RENEW_DATE_LOCAL_STORAGE_KEY = 'firefly-dashboard-user-token-renew-date';
export const SELECTED_WIDGET_IDS_LOCAL_STORAGE_KEY = 'firefly-dashboard-selected-widget-ids';

// Shows Links to different Routes (FireFly_Manager, Salesman, Salesman_Manager) depending on user access levels
const createApolloClient = (args?: { onLogout?: (redirectTo?: string) => void; onSocketConnected?: () => void; onSocketDisconnected?: () => void }) => {
  const { onLogout, onSocketConnected, onSocketDisconnected } = args || {};
  let endpoint = process.env.REACT_APP_API_ENDPOINT;
  let endpointWs = process.env.REACT_APP_API_ENDPOINT_WS;
  const cache = new InMemoryCache();
  // const token = Authentication.getInstance().getToken()

  const getAuthorizationString = () => {
    const token = localStorage.getItem(USER_TOKEN_LOCAL_STORAGE_KEY);
    const authorization = token ? `Bearer ${token}` : null;
    return authorization;
  };

  /** Handles authentication */
  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    const authorization = getAuthorizationString();
    return {
      headers: {
        ...headers,
        authorization,
      },
    };
  });

  /** Handles graphql and connection errors globally */
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    graphQLErrors &&
      graphQLErrors.forEach((error) => {
        if (error.extensions && error.extensions.code === 'TokenInvalidError') {
          //If the token is invalid or expired, go to the login form
          // console.log("Token is invalid. Loging out.")
          // notification.open({
          //   message: "Sessão expirada",
          //   description: "Por favor efetuar login novamente",
          // })

          if (onLogout) {
            // redirect to special login page if inside client details page
            const redirectTo: string = window.location.pathname.startsWith('/client/') ? window.location.href + '/login' : '/';

            onLogout(redirectTo);
          } else {
            console.log(`[Apollo onError] onLogout() skipped`);
          }
        }
      });

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const httpLink = createUploadLink({
    uri: endpoint,
    headers: {
      // authorization: token ? `Bearer ${token}` : '',
      frontEndVersion: process.env.REACT_APP_VERSION,
    },
  });

  // Create a WebSocket link:
  const subscriptionClient = new SubscriptionClient(endpointWs, {
    reconnect: true,
    // only connect the socket when the first subscription is started
    lazy: true,
    connectionParams: () => ({
      // send auth token when the connection is started
      authorization: getAuthorizationString(),
    }),
  });

  subscriptionClient.onConnected(() => onSocketConnected && onSocketConnected());
  subscriptionClient.onReconnected(() => onSocketConnected && onSocketConnected());
  subscriptionClient.onDisconnected(() => onSocketDisconnected && onSocketDisconnected());

  const wsLink = new WebSocketLink(subscriptionClient);

  // wsLink. onConnected(() => onSocketConnected && onSocketConnected())

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const httpAndWebSocketsLink = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
  );

  /** Merge the links */
  const link = ApolloLink.from([errorLink, authLink, httpAndWebSocketsLink]);

  const client = new ApolloClient({ link, cache, connectToDevTools: process.env.NODE_ENV !== 'production' }); // create Apollo client
  return client;
};

const getUserFromLocalStorage = (): User => {
  const userInterface: UserInterface = JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE_KEY));
  return new User(userInterface);
};

if (process.env.REACT_APP_BUGSNAG_API_TOKEN && process.env.NODE_ENV === 'production') {
  const user = getUserFromLocalStorage();
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_TOKEN,
    plugins: [new BugsnagPluginReact()],
    user: {
      email: user?.email,
      id: user?.id,
      name: user?.name,
    },
    appVersion: process.env.REACT_APP_VERSION,
  });
  BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_API_TOKEN })
}

const DashboardRoot = (props: {}) => {
  //Gets token from local storage
  const [token, setToken] = useState<string>(localStorage.getItem(USER_TOKEN_LOCAL_STORAGE_KEY));
  const [user, setUser] = useState<User>(getUserFromLocalStorage());
  const [authenticated, setAuthenticated] = useState<boolean>(!!token);

  useEffect(() => {
    if (!token) {
      //log out case
      localStorage.removeItem(SELECTED_WIDGET_IDS_LOCAL_STORAGE_KEY);
      localStorage.removeItem(USER_TOKEN_RENEW_DATE_LOCAL_STORAGE_KEY);
      localStorage.removeItem(USER_TOKEN_LOCAL_STORAGE_KEY);
      localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
    } else {
      // log in case
      localStorage.setItem(USER_TOKEN_LOCAL_STORAGE_KEY, token);
      localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
    }
    Bugsnag.setUser(user?.id, user?.email, user?.name);
    setAuthenticated(!!token && !!user);
  }, [token, user]);

  const handleLogout = async (redirectTo?: string) => {
    // console.log('handleLogout', authenticated)
    // if (authenticated) {
    if (!process.env.REACT_APP_WHITELABEL_BUILD)
      notification.open({
        message: 'Desconectado',
        description: 'Por favor, efetue o login novamente para continuar',
      });

    // avoids a bug where the redirect url is a JS code function
    const redirectTo2 = (typeof redirectTo !== 'string' || redirectTo?.startsWith('function')) ?
      null : redirectTo;

    setTimeout(async () => {
      await apolloClient.clearStore();
      setUser(null);
      setToken(null);
      if (redirectTo2)
        window.location.href = redirectTo2;
    }, 1000);
    // }
  };

  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false);

  const handleSocketConnected = async () => {
    console.debug('handleSocketConnected');
    setIsSocketConnected(true);
  };

  const handleSocketDisconnected = async () => {
    console.debug('handleSocketDisconnected');
    setIsSocketConnected(false);
  };

  const apolloClient = useMemo(
    () =>
      createApolloClient({
        onLogout: handleLogout,
        onSocketConnected: handleSocketConnected,
        onSocketDisconnected: handleSocketDisconnected,
      }),
    // eslint-disable-next-line
    []
  );

  const handleSuccesfulLogin = async (authPayload: AuthPayload, options?: { redirectTo?: string }) => {
    const { redirectTo } = options || {}

    await apolloClient.clearStore();
    setUser(new User(authPayload.user));
    setToken(authPayload.token);
    localStorage.setItem(USER_TOKEN_RENEW_DATE_LOCAL_STORAGE_KEY, new Date().toISOString());

    if (redirectTo)
      window.location.href = redirectTo;
  };

  const handleSuccessfulTokenRenew = async (authPayload: AuthPayload) => {
    setUser(new User(authPayload.user));
    setToken(authPayload.token);
    localStorage.setItem(USER_TOKEN_RENEW_DATE_LOCAL_STORAGE_KEY, new Date().toISOString());
  };

  /** gets updated user info from the server. Useful for updating the list of agencies the user has access to. */
  const handleRefetchUser = async () => {
    try {
      const queryResult = await apolloClient.query<UserQuery, UserQueryVariables>({
        query: USER_QUERY,
        variables: { id: 'me' },
        fetchPolicy: 'network-only',
      });
      const newUserData = queryResult && queryResult.data && queryResult.data.user;
      if (newUserData) {
        const newUserObj = new User(newUserData);
        setUser(newUserObj);
        message.info('Lista de agências atualizada');
      } else {
        notification.error({ message: 'Erro', description: 'Não foi possível receber dados atualizados do seu usuário. ' + (queryResult.errors || '') });
      }
    } catch (e: any) {
      Bugsnag.notify(e, (event) => event.setUser(user.id, user.email, user.name));
      notification.error({ message: 'Erro', description: 'Não foi possível receber dados atualizados do seu usuário. ' + (e || '') });
    }
  };

  const getExternalLoginToken = (): {
    token: string,
    phone?: string,
    name?: string,
    message?: string,
    redirectTo?: string
  } => {
    const pathname = window.location.pathname; // could be '/'
    if (pathname !== '/')
      return null

    const search = window.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search)

    return {
      token: params.get('token'),
      phone: params.get('phone'),
      name: params.get('name'),
      message: params.get('text'),
      // redirectTo: '/chatOnline/externalChatChannel/'
    }
  }

  const {
    token: externalLoginToken,
    phone: externalLoginClientPhone,
    name: externalLoginClientName,
    message: externalLoginClientMessage,
  } = getExternalLoginToken() || {}
  // console.log({ externalLoginToken })

  // logout if external token detected - will login again automatically through LoginForm
  useEffect(() => {
    if (externalLoginToken && authenticated)
      handleLogout()
  }, [])

  const openRoutes = [
    ...PagesController.getReactRouterRoutes({ openRoutesOnly: true, whitelabelRoutesOnly: !!process.env.REACT_APP_WHITELABEL_BUILD }),

    <Route key="login" path="/" exact render={() =>
      <LoginForm
        onSuccessfulLogin={handleSuccesfulLogin}
        isRefreshingUserToken={token && !user}
        externalLoginToken={externalLoginToken}
        externalLoginClientPhone={externalLoginClientPhone}
        externalLoginClientName={externalLoginClientName}
        externalLoginClientMessage={externalLoginClientMessage}
      />
    } />,
    // if no other routes match, redirect to the login page
    <Route key="login_2" render={() =>
      <LoginForm
        onSuccessfulLogin={handleSuccesfulLogin}
        isRefreshingUserToken={token && !user}
        externalLoginToken={externalLoginToken}
        externalLoginClientPhone={externalLoginClientPhone}
        externalLoginClientName={externalLoginClientName}
        externalLoginClientMessage={externalLoginClientMessage}
      />} />,
  ];

  return (
    <ApolloProvider client={apolloClient}>
      <ConfigProvider locale={ptBR}>
        <UserTokenRenewer token={token} onSuccessfulTokenRenew={handleSuccessfulTokenRenew} />
        <BrowserRouter /* basename="/dashboard" */>
          <ErrorBoundary
            errorFallback={
              <Alert
                icon={<FrownOutlined />}
                showIcon
                type="error"
                message="Erro"
                description="Ocorreu um erro inesperado. Verifique o console do navegador para mais informações."
              />
            }
            networkErrorFallback={<NetworkErrorPage />}
          >
            <Suspense
              fallback={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '75vh' }}>
                  <Spin tip="Carregando página..." />
                  {/* {console.log('suspense fallback')} */}
                </div>
              }
            >
              {authenticated ? (
                // Shows dashboard
                <DashboardAuthenticatedRootPage onLogout={handleLogout} user={user} onRefetchAgencies={handleRefetchUser} isSocketConnected={isSocketConnected} />
              ) : (
                // Shows Login form (or open routes)
                <Switch>{openRoutes}</Switch>
              )}
            </Suspense>
          </ErrorBoundary>
        </BrowserRouter>
      </ConfigProvider>
    </ApolloProvider>
  );
};

export default DashboardRoot;
